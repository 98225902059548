/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ImagePart from '../layout/ImagePart';
import Page_info from '../components/Page_info';
import svgImg from '../image/CompanyInfo-img/BG_1.svg';

const CompanyInfo = () => {

  const compImage = require('../image/CompanyInfo-img/BG_2.jpg');
  const part1Img = require('../image/CompanyInfo-img/img_1.jpg');
  const part2Img = require('../image/CompanyInfo-img/img_2.jpg');
  const part3Img = require('../image/CompanyInfo-img/img_3.jpg');

  return (
    <div className='company'>
        {/*   形象圖  */}
        <ImagePart src={compImage}/>
        <div className="company_title">
          <div className="company_title_up">
            <p>About</p>
            <span>關於晨豐</span>
          </div>
          <div className="company_title_down">
            <p>VastLife</p>
          </div>
        </div>
        {/*   內容  */}
        <Page_info text='公司介紹'/>
        <div className="company_content">
            <div className="company_content_Bg">
              <img src={svgImg} alt="背景圖" />
            </div>
            <div className="company_content_title">
              <p>
                本公司成立於民國99年6月，是一個專業的地理資訊技術整合服務公司，以地理資訊系統(GIS)、全球定位系統(GPS)、數值航測製圖、電腦製圖等資訊科技，從事地理資源經營管理之諮詢顧問及專案整體性設計規劃開發業務。
              </p>
            </div>
            <div className="company_content_text">
              <div className="company_content_part-1">
                <div className="company_content_part-1-img">
                  <img src={part1Img} alt="圖1" />
                </div>
                <div className="company_content_part-1-text">
                  <p>在地理資訊系統與都市養護工程計畫、縣市綜合發展計畫、國土計畫均有多年之實務經驗，可為客戶提供最佳及最專業的服務。業務內容包括調查、資料庫建置，到系統規劃、分析、設計，及軟硬體、網路架構之整合建置，提供客戶全方位的 MIS、GIS、GPS、RS、LBS 等專業資訊顧問服務之整體解決(TotalSolution)方案。</p>
                </div>
              </div>
              <div className="company_content_part-2">
                <div className="company_content_part-2-text">
                  <p>本團隊為國內少數幾家專業地理資訊公司之一，成員結合了電腦資訊、土木、地理、環工、土地資源管理、都計，企業管理等各領域之專才，不論是GIS特有之空間位相及圖形處理技術，資料庫規劃設計、應用系統開發技術，或是GIS基本需求之數化建檔、委辦單位業務流程規劃等方面都有豐富且成功之經驗。</p>
                </div>
                <div className="company_content_part-2-img">
                  <img src={part2Img} alt="圖1" />
                </div>
              </div>
              <div className="company_content_part-3">
                <div className="company_content_part-3-img">
                  <img src={part3Img} alt="圖1" />
                </div>
                <div className="company_content_part-3-text">
                  <p>為期以更多的科技水準，及更堅強的人員素質，提供客戶更高品質的服務，配合政府目前正積極推動之國土資訊化腳步，帶動國內地理資訊產業。</p>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default CompanyInfo