/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import Ach_home_card from './Ach_home_card';
import Button from './Button';
import { Link } from 'react-router-dom';

const Achievement_Section = () => {
    const Ach1 = require('../image/Ach-img/高雄房地產億年旺網站/img_1.png');
    const Ach2 = require('../image/Ach-img/台北市都發局/img_1.png');
    const Ach3 = require('../image/Ach-img/屏東縣政府陳情案件圖示系統建置案/img_1.png');
    const Ach4 = require('../image/Ach-img/屏東農田水利會「抽水機暨灌溉管理系統建置計畫」/img_1.png');
  return (
    <div className="achievement">
        <p className="achievement_title">
             成果展示 Achievement display
        </p>
        <div className="achievement_cards">
            <Ach_home_card
                img={Ach1} 
                title='高雄房地產億年旺網站-增修功能建置案'/>
            <Ach_home_card 
                img={Ach2}
                title='市政規劃及管理可視化資訊輔助決策系統建置案'/>
            <Ach_home_card 
                img={Ach3}
                title='屏東縣政府陳情案件圖示系統建置案'/>
            <Ach_home_card 
                img={Ach4}
                title='屏東農田水利會「抽水機暨灌溉管理系統建置計畫」'/>
        </div>
        <div className="achievement_btn">
            <Link to='/achievement'><Button text='View More'/></Link>
        </div>
        <div className="achievement_background"></div>
    </div>
  )
}

export default Achievement_Section