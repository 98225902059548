import React from 'react';


const Busniess_card = (card) => {

  return (
    <div className='busniess_card'>
        <div className="busniess_card_img">
          <div className="busniess_card_img_overlay"></div>
          <img src={card.img} alt="業務範圍照片" />
          <div className="busniess_card_title">
              <p>{card.title}</p>
              <span>{card.enTitle}</span>
          </div>
        </div>
        <div className="busniess_card_list">
        <ul>
          {card.services.map(service => (
            <li key={service.id}>{service.title}</li>
          ))}
        </ul>
        </div>
    </div>
  )
}

export default Busniess_card