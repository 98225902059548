/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ImagePart_2 from '../layout/ImagePart_2';
import Page_info from '../components/Page_info';
import GoogleMap from '../components/GoogleMap';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FaxIcon from '@mui/icons-material/Fax';



const Contact = () => {

  const background_img = require('../image/Contact-img/img_1.jpg');

  return (
    <div className='contact'>
    {/*   形象圖  */}
      <ImagePart_2
          enTitle_L='Contact'
          enTitle_R='Us'
          zwTitle='聯絡我們'
      />
    {/*   內容  */}
      <Page_info text='公司據點'/>
      <div className="contact_area">
        <div className="contact_background">
          <img src={background_img} alt="" />
          <div className="contact_background_overlay"></div>
        </div>
        <div className="contact_content">
          <div className="contact_info">
            <div className="contact_phone">
              <LocalPhoneIcon className='contact_icon'/>
              <p>電話 : 07-2258706</p>
            </div>
            <div className="contact_fax">
              <FaxIcon  className='contact_icon'/>
              <p>傳真 : 07-2253901</p>
            </div>
          </div>
          <div className="contact_map">
            <GoogleMap/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact