import React from 'react'

const ImagePart_2 = (img) => {

  const busninessImg = require('../image/image_BG.jpg');

  return (
    <div className='image_part2'>
      <img src={busninessImg} alt="形象圖" />
      <div className="image_part2_titles">
        <div className="image_part2_en_title">
          <p>{img.enTitle_L}</p>
          <span>{img.enTitle_R}</span>
        </div>
        <div className="image_part2_zw_title">
          <p>{img.zwTitle}</p>
        </div>
      </div>
    </div>
  )
}

export default ImagePart_2