import React from 'react'

const Ach_home_card = (ach) => {
  return (
    <div className="ach_home">
        <div className="ach_home_img">
            <img src={ach.img} alt="成果展示圖" />
        </div>
        <p className="ach_home_title">{ach.title}</p>
    </div>
  )
}

export default Ach_home_card