import React from 'react'

const GoogleMap = () => {

    const mapEmbedCode = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6194.244160470576!2d120.3030652433715!3d22.61200807219319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e04c0cd84c481%3A0x29b64e759f6735eb!2z5pmo6LGQ56eR5oqA6IKh5Lu95pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2stw!4v1691114522802!5m2!1szh-TW!2stw" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';

  return (
    <div>
       <div className='google_map' dangerouslySetInnerHTML={{ __html: mapEmbedCode }} />
    </div>
  )
}

export default GoogleMap