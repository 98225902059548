import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TheHeader = () => {
  
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const icon = require('../image/icon.png');

  // 跳轉每個page都回到視窗最上層
  const scrollToTop = () => {
    window.scrollTo(0, 0); 
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);

  };

  return (
    <div className='nav'>
      <div className="nav_icon">
        <img src={icon} alt="公司icon" />
        <Link to='/'><p>晨豐科技股份有限公司</p></Link>
      </div>
      <div className='nav_routers'>
        <li ><Link className='nav_link' to='/home' onClick={scrollToTop}>首頁</Link></li>
        <li ><Link className='nav_link' to='/companyinfo' onClick={scrollToTop}>公司簡介</Link></li>
        <li ><Link className='nav_link' to='/business' onClick={scrollToTop}>業務範圍</Link></li>
        <li ><Link className='nav_link' to='/achievement' onClick={scrollToTop}>成果展示</Link></li>
        <li ><Link className='nav_link' to='/contact' onClick={scrollToTop}>聯絡我們</Link></li>
      </div>

      {/* Menu Icon */}
      <div className='nav_toggle_btn' onClick={toggleDropdown} >
        <DensityMediumIcon 
          className='nav_toggle_icon'
          style={ dropdownVisible ? {display:'none'} : {display:'flex'} }
          />
        <p style={ dropdownVisible ? {display:'none'} : {display:'flex'} }>MENU</p>
         {/* Dropdown Menu */}
        <div className={dropdownVisible ? 'nav_dropdown_menu_open' : 'nav_dropdown_menu' }>
            <ArrowForwardIosIcon className='nav_dropdown_menu_icon'/>
            <li ><Link className='nav_link' to='/home' onClick={scrollToTop}>首頁</Link></li>
            <li ><Link className='nav_link' to='/companyinfo' onClick={scrollToTop}>公司簡介</Link></li>
            <li ><Link className='nav_link' to='/business' onClick={scrollToTop}>業務範圍</Link></li>
            <li ><Link className='nav_link' to='/achievement' onClick={scrollToTop}>成果展示</Link></li>
            <li ><Link className='nav_link' to='/contact' onClick={scrollToTop}>聯絡我們</Link></li>
        </div>
      </div>


   

    </div>
  )
}

export default TheHeader