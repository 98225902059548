import React from 'react'

const ImagePart = (img) => {
  return (
    <div className='image_part'>
        <img src={img.src} alt="形象圖" />
    </div>
  )
}

export default ImagePart