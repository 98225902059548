/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ImagePart_2 from '../layout/ImagePart_2';
import Page_info from '../components/Page_info';
import Achievement_card from '../components/Achievement_card';
import achieveData from '../data/Achievement_card.json';
import { Link } from 'react-router-dom';

const Achievement = () => {

  const background_img = require('../image/Ach-img/BG.jpg');

  return (
    <div className='achievement_page'>
        {/*   形象圖  */}
        <ImagePart_2 
        enTitle_L='Achievement'
        zwTitle='成果展示'
        />
        {/*   內容  */}
        <Page_info text='專案實績'/>
        <div className="achievement_page_content">
          <div className="achievement_page_Bg">
            <img src={background_img} alt="背景圖片" />
          </div>
          {achieveData.achieveData.map((item, i) => (
            <Link to={`/achieveDisplay/${item.id}`} key={i}>
              <Achievement_card
                title= {item.title}
                content= {item.content}
                image= {require(`../image/Ach-img/${item.image[0]}`)}
              />
            </Link>
        ))}
        </div>
    </div>
  )
}

export default Achievement