import React from 'react';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import HomeIcon from '@mui/icons-material/Home';

const TheFooter = () => {

    // 跳轉每個page都回到視窗最上層
    const scrollToTop = () => {
      window.scrollTo(0, 0); 
    };  

  return (
    <div className='footer'>
      <div className="footer_container">
        <div className="footer_left">
            <p className='footer_left_title'>關於我們 ABOUT US</p>
            <p className='footer_left_contant'>
              由一群熱心且經驗豐富的專家所組成，特別是在地理資訊系統與都市養護工程計畫
              、縣市綜合發展計畫、國土計畫均有多年之實務經驗，可為客戶提供最佳及最專業的服務。
            </p>
        </div>
        <div className="footer_right">
            <div className="footer_list">
               <li><Link to='/companyinfo' onClick={scrollToTop}>公司簡介</Link></li>
               <li><Link to='/business' onClick={scrollToTop}>業務範圍</Link></li>
               <li><Link to='/achievement' onClick={scrollToTop}>成果展示</Link></li>
               <li><Link to='/contact' onClick={scrollToTop}>聯絡我們</Link></li>
            </div>
            <div className="footer_contact">
              <div className="footer_contact_time">
                <div className="footer_contact_circle">
                  <AccessTimeIcon className='footer_contact_icon'/>
                </div>
                <div className="footer_contact_text">
                  <p>星期一 ~ 星期五 </p>
                  <p>AM 9:00 - PM 06:00</p>
                </div>
              </div>
              <div className="footer_contact_phone">
                <div className="footer_contact_circle">
                  <LocalPhoneIcon className='footer_contact_icon'/>
                </div>
                <div className="footer_contact_text">
                  <p>Tel : 07-2258706</p>
                  <p>Fax : 07-2253901</p>
                </div>
              </div>
              <div className="footer_contact_address">
                <div className="footer_contact_circle">
                  <HomeIcon className='footer_contact_icon'/>
                </div>
                <div className="footer_contact_text">
                  <p>高雄市前鎮區一心二路119號7樓之4</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div className="footer_copyright">
        <p>Copyright © 2023 晨豐科技股份有限公司版權所有</p>
      </div>
    </div>
  )
}

export default TheFooter