/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ImagePart_2 from '../layout/ImagePart_2';
import Page_info from '../components/Page_info';
import { useParams } from 'react-router-dom';
import achieveData from '../data/Achievement_card.json';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const AchieveDisplay = () => {

    const background_img = require('../image/Ach-img/BG.jpg');
  
    const { Id } = useParams();
    const data = achieveData.achieveData.find((info) => info.id === +Id);

    // 狀態用於控制模態的顯示
    const [showModal, setShowModal] = useState(false);
    const [modalImg, setModalImg] = useState('');
  
    // 開啟模態並設定要顯示的圖片
    const handleImageClick = (imgSrc) => {
        setModalImg(imgSrc);
        setShowModal(true);
    };
  
    // 關閉模態
     const closeModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (showModal) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [showModal]);

  return (
    <div className='achieve_display'>
          {/*   形象圖  */}
          <ImagePart_2 
        enTitle_L='Achievement'
        zwTitle='成果展示'
        />
        {/*   內容  */}
        <Page_info text='專案實績'/>

        <div className="achieve_display_total">
            <div className="achievement_page_Bg">
                <img src={background_img} alt="背景圖片" />
            </div>

            <div className="achieve_display_content">
                <div className="achieve_display_content_title">
                    <p>{data.title}</p>
                </div>

                <div className="achieve_display_content_text">
                    <p>{data.content}</p>
                </div>

                <div className="achieve_display_img_content">
                 {data.image.map((img, index) => (
                        <div className="achieve_display_img" 
                             key={index}
                             onClick={() => handleImageClick(require(`../image/Ach-img/${img}`))}
                        >
                            <img src={require(`../image/Ach-img/${img}`)} alt={`圖片${index + 1}`} />
                        </div>
                 ))}
                </div>
                <div className='achieve_display_lastpage'>
                    <Link to='/achievement' >
                        <p>| 回上一頁 |</p>
                    </Link>
                </div>
            </div>
        </div>


        {/* 彈出視窗 */}
        {showModal && (
            <div className="modal" onClick={closeModal}>
                <div className="modal-content">
                    <img src={modalImg} alt="放大圖片"/>
                </div>
            </div>
        )}
    </div>
  )
}

export default AchieveDisplay