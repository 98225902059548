/* eslint-disable array-callback-return */
import React from 'react';

const Achievement_card = ({ title, content, image }) => {

  return (
    <div className='achievement_card'> 
        <div className="achievement_card_img">
            <img src= {image} alt='專案圖片'/>
        </div>
        <div className="achievement_card_content">
          <div className="achievement_card_title">
            <p>{title}</p>
          </div>
          <div className="achievement_card_text">
            <p>{content}</p>
          </div>
        </div>
    </div>
  )

}

export default Achievement_card