import React from 'react'

const Tech_card = (card) => {
  return (
    <div className='tech_card'>
        <div className='tech_card_polygon_left'></div>
        <p>{card.text}</p>
        <div className='tech_card_polygon_right'></div>
    </div>
  )
}

export default Tech_card