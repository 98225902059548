import React from 'react'

const Page_info = (info) => {
  return (
    <div className='page_info'>
        <p>{info.text}</p>
    </div>
  )
}

export default Page_info