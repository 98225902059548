import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
//----------Page-----------
import Home from './Pages/Home'
import CompanyInfo from './Pages/CompanyInfo'
import Business from './Pages/Business'
import Achievement from './Pages/Achievement'
import Contact from './Pages/Contact'
import AchieveDisplay from './Pages/AchieveDisplay'
import TheHeader from './layout/TheHeader'
import TheFooter from './layout/TheFooter'
import '../src/style/style.scss';


const App = () => {

  return (
    <Router>
      <TheHeader/>
      <Routes>
        <Route index element = {<Home/>}/>
        <Route path='home' element = {<Home/>}/>
        <Route path='companyinfo' element = {<CompanyInfo/>}/>
        <Route path='business' element = {<Business/>}/>
        <Route path='achievement' element = {<Achievement/>}/>
        <Route path='contact' element = {<Contact/>}/>
        <Route path='achieveDisplay/:Id' element = {<AchieveDisplay/>}/>
      </Routes>
      <TheFooter/>
    </Router>
  )
}

export default App