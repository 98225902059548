import React from 'react'

const WhyUs = () => {
  return (
    <div className="why">
        <div className="why_title">
            <div className="why_title_div"></div>
            <p className='why_title_text'>Why Us ?</p>
        </div>
        <div className="why_container">
            <div className="why_container_left">
                <p className="why_left_title">
                    在地理資訊系統與都市養護工程計畫、縣市綜合發展計畫、
                    國土計畫均有多年之實務經驗，可為客戶提供最佳及最專業的服務。
                </p>
                <p className="why_left_text">
                    不論是GIS特有之空間位相及圖形處理技術，資料庫規劃設計、
                    應用系統開發技術，或是GIS基本需求之數化建檔、委辦單位業
                    務流程規劃等方面都有豐富且成功之經驗。
                </p>
            </div>
            <div className="why_container_right">
                <iframe id="ytplayer" 
                    title='晨豐科技GIS結合BIM展示影片'
                    type="text/html" 
                    width="86%"
                    height="auto"
                    src="https://www.youtube.com/embed/Z5eQ0c7Ze5I?autoplay=1&mute=1&loop=1&playlist=Z5eQ0c7Ze5I"
                    frameborder="0" 
                    allowfullscreen                   
                 />
            </div>
        </div> 
    </div>
  )
}

export default WhyUs