/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ImagePart_2 from '../layout/ImagePart_2';
import Page_info from '../components/Page_info';
import Busniess_card from '../components/Busniess_card';
import servicesData from '../data/Business_card.json';

const Business = () => {

  const background_img = require('../image/Business-img/BG_1.jpg')
  const img1 = require('../image/Business-img/img_1.jpg');
  const img2 = require('../image/Business-img/img_2.jpg');
  const img3 = require('../image/Business-img/img_3.jpg');
  const geographicServices = servicesData.geographicServices;
  const digitalImageServices = servicesData.digitalImageServices;
  const otherServices = servicesData.otherServices;

  return (
    <div className='business_page'>
      {/*   形象圖  */}
      <ImagePart_2 
        enTitle_L='Business'
        enTitle_R='Line'
        zwTitle='業務範圍'
      />
      {/*   內容  */}
      <Page_info text='服務項目'/>
      <div className="business_page_content">
        <div className="business_page_Bg">
          <img src={background_img} alt="背景圖" />
        </div>
        <Busniess_card
          img={img1}
          title='地理資訊'
          enTitle='Geographic Information'
          services={geographicServices}
        />
        <Busniess_card
          img={img2}
          title='數值影像處理'
          enTitle='Digital Image Processing'
          services={digitalImageServices}
        />
        <Busniess_card
          img={img3}
          title='其他服務項目'
          enTitle='Other Service'
          services={otherServices}
        />
      </div>
    </div>
  )
}
 export default Business