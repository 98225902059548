import React from 'react';
import Button from './Button';
import { Link } from 'react-router-dom';

const Business_Section = () => {
  return (
    <div className='business'>
        <div className="business_title">
            <div className="business_title_div"></div>
            <p className='business_title_text'>業務範圍</p>
        </div>
        <div className="business_cards">
            <div className="business_card"><p>地理資訊</p><p>系統分析設計</p></div>
            <div className="business_card"><p>地理資料庫</p><p>設計規劃</p></div>
            <div className="business_card"><p>影像與數值地圖套疊</p></div>
            <div className="business_card"><p>公共管理規劃設計</p></div>
            <div className="business_card"><p>GIS相關技術研發</p></div>
            <div className="business_card"><p>都市區域規劃、縣市綜合發展、國土計畫</p></div>
            <div className="business_card"><p>土地利用規劃</p><p>使用分區證明核發</p></div>
            <div className="business_card"><p>系統整合</p></div>
        </div>
        <div className="business_btn">
            <Link to='/business'><Button text='View More'/></Link>
        </div>
    </div>
  )
}

export default Business_Section