/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import svgImg from '.././image/Home-img/BG_3.svg';
import Tech_card from './Tech_card';

const Technology_Section = () => {
  return (
    <div className="technology">
        <div className="technology_title">
            <div className="technology_title_div"></div>
            <p className='technology_title_text'>技術領域</p>
        </div>
        <div className="technology_cards">
            <Tech_card text='GIS'/>
            <Tech_card text='Python'/>
            <Tech_card text='C#'/>
            <Tech_card text='BIM'/>
            <Tech_card text='JavaScript'/>
            <Tech_card text='SQL Database'/>
            <Tech_card text='MIS'/>
            <Tech_card text='Java'/>
            <Tech_card text='RWD'/>
            <Tech_card text='Android App'/>
        </div>
        <div className="technology_background">
            <img className='technology_img' src={svgImg} alt="蜂巢圖"  />
        </div>
    </div>
  )
}

export default Technology_Section